/* Shake animation */
.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

/* Notification item */
.notification-item {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.message {
  font-weight: 500;
  margin: 0;
}

.time {
  color: grey;
  font-size: 0.85rem;
  margin: 0;
}
/* Alternating row colors */
.notification-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.notification-item.even {
  background-color: #f9f9f9;
}

.notification-item.odd {
  background-color: #fff;
}

.message {
  font-weight: 500;
}

.time {
  color: grey;
  font-size: 0.85rem;
}

/* Style the view button */
.view-btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 5px 10px;
  font-size: 0.85rem;
  border-radius: 3px;
  cursor: pointer;
}

.view-btn:hover {
  background-color: #0056b3;
}
