.voice-command-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
  }
  
  .wave-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .wave {
    width: 5px;
    height: 20px;
    margin: 0 3px;
    background-color: blue;
    display: inline-block;
    animation: wave-animation 1.2s infinite;
    opacity: 0.5;
  }
  
  @keyframes wave-animation {
    0% {
      height: 10px;
      opacity: 0.3;
    }
    50% {
      height: 30px;
      opacity: 1;
    }
    100% {
      height: 10px;
      opacity: 0.3;
    }
  }
  
  /* Active wave (when voice is detected) */
  .wave-active {
    animation-duration: 0.6s;
    background-color: red;
  }
  
  @media (max-width: 600px) {
    .voice-command-modal {
      width: 90%; /* Responsive width for smaller screens */
    }
  }
  .cut-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 15px;
  }
  