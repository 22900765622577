.header-style {
    font-size: 14px;
    font-weight: bold;
    background-color: #4CB5F5;
    color: white;
}
@media (max-width: 600px) {
    .table-container {
        display: flex;
        flex-direction: column;
    }

    .table-row {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .table-cell {
        margin-bottom: 5px;
    }

    .header-cell {
        font-weight: bold;
    }
}
