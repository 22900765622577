.linkStyle {
  text-decoration: none;
  cursor: pointer;
}

.linkStyle:hover {
  color: rgb(10, 10, 216);
  text-decoration: underline;
  cursor: pointer;
}

.headerCellStyle {
  font-size: 14;
  font-weight: bold;
  background-color: #4CB5F5;
  color: white;
}

;

.swal-popup {
  z-index: 9999 !important;
  /* Increase z-index to ensure it is on top */
}